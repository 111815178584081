<template>
  <my-dialog title="רשימת חנויות" v-model="openModal" :max-width="400">
    <template v-slot:content>
      <v-simple-table v-if="credits.length">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="">שם החברה</th>
              <th class="">סכום מאושר</th>
              <th class="text-left">QR code</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="{ credit, amount } in credits" :key="credit._id">
              <td>{{ credit.company.name }}</td>
              <td>
                {{ amount }}
                <v-icon x-small color="secondary">mdi-currency-ils</v-icon>
              </td>
              <td class="text-left">
                <v-btn icon @click="openQR(credit)" class="qr-icon">
                  <v-icon color="secondary">mdi-qrcode-scan</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-else class="text-center">
        <h3>אין חנויות מאושרות עדיין</h3>
      </div>
      <QrCodeHandler
        v-if="dialogQr"
        v-model="dialogQr"
        :credit="currentCredit"
        :kid-id="kid._id"
      />
    </template>
  </my-dialog>
</template>
<script>
import MyDialog from "@/components/generics/MyDialog.vue";
import QrCodeHandler from "./QrCodeHandler.vue";

export default {
  name: "KidPaymentConfirmation",
  components: {
    MyDialog,
    QrCodeHandler,
  },
  props: {
    value: Boolean,
    kid: Object,
    isNew: { type: Boolean, default: false },
  },
  data: () => ({
    dialogQr: false,
    currentCredit: null,
  }),
  computed: {
    credits() {
      return this.kid.credits.filter((c) => c.amount);
    },
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    openQR(credit) {
      this.dialogQr = true;
      this.currentCredit = credit;
    },
  },
  async mounted() {},
};
</script>
<style scoped lang="scss">
.qr-icon {
  border: 1px solid #000;
}
:deep(table) {
  table-layout: fixed;
  border: 1px solid gray;
}
</style>
