<template>
  <my-dialog title="רשימת חנויות" v-model="openModal" :max-width="400">
    <template v-slot:content>
      <v-row align="center">
        <v-col cols="12">
          <v-tabs v-model="tabs" centered>
            <v-tab>עריכה</v-tab>
            <v-tab>רשימה</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-row align="center">
                <v-col cols="12">
                  <v-autocomplete
                    v-model="creditSelected"
                    :items="credits"
                    outlined
                    dense
                    label="בחר מקום קניה"
                    return-object
                    item-text="company.name"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="creditSelected">
                <v-col>
                  <CompanyInputPay
                    v-if="Object.keys(creditSelected).length"
                    :key="creditSelected._id"
                    :credit="creditSelected"
                    :amount="
                      creditSelected?.kidAmount
                        ? Number(creditSelected?.kidAmount)
                        : 0
                    "
                    @updateAmount="updateAmount"
                    @remove="removeCredit"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col v-if="kid.credits?.length">
                  <v-simple-table>
                    <template v-slot:default v-if="credits.length">
                      <thead>
                        <tr>
                          <th class="">שם</th>
                          <th class="">סכום</th>
                          <th class="text-center">פעולה</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="{ credit, amount, _id } in kid.credits"
                          :key="_id"
                        >
                          <td class="">
                            <span>
                              {{ findCredit(credit)?.company?.name }}
                            </span>
                          </td>
                          <td class="">
                            <span>
                              {{ amount }}
                              <v-icon x-small color="secondary">
                                mdi-currency-ils
                              </v-icon>
                            </span>
                          </td>
                          <td class="d-flex justify-space-around text-left">
                            <v-btn icon class="qr-icon">
                              <v-icon
                                @click="openQR(credit)"
                                small
                                color="secondary"
                              >
                                mdi-qrcode-scan
                              </v-icon>
                            </v-btn>
                            <v-btn icon class="qr-icon">
                              <v-icon
                                small
                                color="success"
                                @click="editCredit(credit, amount)"
                              >
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                            <v-btn icon class="qr-icon">
                              <v-icon
                                @click="removeCredit(credit)"
                                small
                                color="error"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <QrCodeHandler
                    v-if="dialogQr"
                    v-model="dialogQr"
                    :credit="currentCredit"
                    :kid-id="kid._id"
                  />
                </v-col>
                <v-col v-else class="text-center">
                  <h3>אין עדיין חנויות מאושרות</h3>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-row v-if="err">
        <v-col class="error--text text-center">
          {{ err }}
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-btn
        v-if="!tabs"
        text
        color="primary"
        v-text="'שמור'"
        @click="submit"
      />
    </template>
  </my-dialog>
</template>
<script>
import CompanyInputPay from "../CompanyInputPay.vue";
import MyDialog from "../generics/MyDialog.vue";
import { cloneDeep } from "lodash";
import QrCodeHandler from "./QrCodeHandler.vue";

export default {
  name: "FatherPaymentConfirmation",
  components: { MyDialog, CompanyInputPay, QrCodeHandler },
  props: {
    value: Boolean,
    kid: Object,
    isNew: { type: Boolean, default: false },
  },
  data: () => ({
    dialogQr: false,
    currentCredit: null,
    creditSelected: null,
    kidCreditsAmount: [],
    err: null,
    tabs: null,
  }),
  computed: {
    credits() {
      return cloneDeep(this.$store.getters.creditsApproved);
    },
    allCredits() {
      return this.$store.getters.credits;
    },

    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    findCredit(id) {
      return this.allCredits.find((c) => c._id == id);
    },
    openQR(credit) {
      this.dialogQr = true;
      this.currentCredit = this.findCredit(credit);
    },
    async removeCredit(id) {
      await this.$store.dispatch("credit/destroy/kid", {
        creditId: id,
        kidId: this.kid._id,
      });
    },
    editCredit(credit, amount) {
      amount = Number(amount);
      this.creditSelected = this.findCredit(credit);
      this.creditSelected["kidAmount"] = amount;
      this.tabs = 0;
    },
    updateAmount(credit, amount) {
      amount = Number(amount);
      this.creditSelected["kidAmount"] = amount;
      this.creditSelected["amount"] = amount;
      this.creditSelected["credit"] = credit;
    },
    async lodeCredits() {
      await this.$store.dispatch("credit/index");
    },
    async submit() {
      let companyId =
        this.creditSelected["company"]?._id || this.creditSelected["company"];
      companyId =
        companyId ||
        this.creditSelected["credit"]?.company?._id ||
        this.creditSelected["credit"]?.company;
      if (!this.validateForm()) {
        return this.createErr(""); //TODO: create validation error message
      }

      const kid = this.kid;

      const data = {};
      if (!kid["credits"]?.length) {
        kid["credits"] = [];
        data["credit"] =
          this.creditSelected["credit"]?._id || this.creditSelected["credit"];
        data["company"] = companyId;
      } else {
        const oldCredit = kid["credits"].find((c) => c.company === companyId);
        kid["credits"] = kid["credits"].filter((c) => {
          return c.company !== companyId;
        });
        data["company"] = oldCredit.company;
        data["credit"] = oldCredit.credit;
      }
      data["amount"] = this.creditSelected["kidAmount"];

      kid["credits"].push(data);

      kid["kidId"] = this.kid._id;
      await this.$store.dispatch("user/edit-kid", kid);
      this.openModal = false;
    },
    validateForm() {
      //TODO: add form validations here
      return true;
    },
    createErr(msg) {
      this.err = msg;
      setTimeout(() => {
        this.err = null;
      }, 2000);
      return;
    },
  },
  async mounted() {
    if (!this.credits.length) {
      await this.lodeCredits();
    }
    const kidCredits = this.kid?.credits || [];
    if (kidCredits.length) {
      kidCredits.forEach(({ credit, amount }) => {
        const creditId = credit?._id || credit;
        const index = this.credits.findIndex((c) => c._id == creditId);
        if (index > -1) {
          this.credits[index]["kidAmount"] = amount;
        }
      });
    }
  },
};
</script>
