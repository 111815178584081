<template>
  <div class="action-history header-color" v-if="kid">
    <div v-if="isAdmin" />
    <my-header
      v-else-if="user"
      btnLeft="mdi-dots-vertical"
      btnRight="mdi-arrow-right"
      @clickBtnLeft="openMenuKid"
      @clickBtnRight="goBack"
      :title="`הארנק של: ${kid.name}`"
    />
    <my-header v-else title="הארנק שלי PayKid" />

    <v-container
      class="form-container d-flex justify-center flex-column body-color"
    >
      <div
        class="avatar-img d-flex justify-center mt-n16 mouse-pointer"
        @click="setSelectColor"
      >
        <v-avatar size="128">
          <v-img :src="`/imgs/users/${kid.color}.png`" lazy-src="/person.png" />
        </v-avatar>
      </div>
      <div class="name text-center pt-2">
        {{ kid.name }}
      </div>
      <!-- <q-rode src="123424234" /> -->
      <div class="kid-balance text-center pa-10">
        סך הכל יש לך:
        <div class="kid-balance">
          <span :class="`${balance >= 0 ? 'primary--text' : 'red--text'} `">
            {{ Math.abs(balance) }} {{ balance >= 0 ? "" : "-" }}
          </span>
          ₪
        </div>
      </div>
      <div class="primaryText--text font-weight-bold ml-8 mb-2">
        פעולות אחרונות
      </div>
      <div v-if="loading" class="progress d-flex justify-center pa-16">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        />
      </div>
      <!-- :actionsList="actionsList" -->
      <list-action-history
        v-else
        :sheet="sheet"
        :fieldParameter="fieldParameter"
        @handleKid="save"
        @openShowEditAction="openShowEditAction"
      />

      <v-footer
        v-if="!isAdmin"
        app
        style="background: center"
        class="center-all"
      >
        <v-row class="d-flex justify-center mb-3" max-width="500px">
          <v-col
            cols="2"
            xs="2"
            sm="2"
            md="2"
            :class="isMobileMode ? 'center-all' : 'd-flex justify-end'"
            v-if="!isKidMode"
          >
            <v-btn fab dark color="primary" @click="toPayKid">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </v-col>
          <v-col v-if="isMobileMode && !isKidMode" cols="1"></v-col>
          <v-col
            cols="2"
            xs="2"
            sm="2"
            md="2"
            :class="isMobileMode || isKidMode ? 'center-all' : ''"
          >
            <v-btn fab dark color="primary" @click="openPayDialog">
              <v-icon dark>mdi-cart</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" xs="6" sm="6" md="7" v-if="showMenu && !isKidMode">
            <v-bottom-sheet v-model="showMenu" max-width="700px">
              <v-sheet max-height="100%" class="py-10">
                <v-row
                  justify="center"
                  v-for="btn in settingButtons"
                  :key="btn.name"
                >
                  <v-col cols="8" class="pa-5">
                    <v-btn
                      block
                      color="primary"
                      elevation="2"
                      @click="btnEditClick(btn.onClick)"
                    >
                      <v-list-item-title v-text="btn.name" />
                      <v-icon>{{ btn.icon }}</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-bottom-sheet>
          </v-col>
        </v-row>
        <v-bottom-sheet
          v-if="!isKidMode"
          v-model="selectColor"
          max-width="700px"
        >
          <v-sheet max-height="100%" class="py-10">
            <select-color-avatar @setSelectColor="setSelectColor" />
          </v-sheet>
        </v-bottom-sheet>
        <div class="d-flex justify-space-around mb-3" max-width="500px"></div>
      </v-footer>
    </v-container>
    <father-payment-confirmation
      v-if="openFatherPayDialog"
      v-model="openFatherPayDialog"
      :kid="kid"
    />
    <KidPaymentConfirmation
      v-if="openKidPayDialog"
      v-model="openKidPayDialog"
      :kid="kid"
    />
    <handle-kid
      v-model="sheet"
      @handleKid="save"
      :fieldParameter="fieldParameter"
      :isNew="false"
    />
    <cron-job-pay
      v-if="openDialog"
      v-model="openDialog"
      :allowance="kid.allowance[0]"
      @closeMenu="closeMenu"
    />
  </div>
  <div v-else />
</template>

<script>
import MyHeader from "@/components/MyHeader.vue";
import HandleKid from "@/components/HandleKid.vue";
import SelectColorAvatar from "@/components/SelectColorAvatar.vue";
import ListActionHistory from "@/components/ListActionHistory.vue";
import CronJobPay from "@/components/CronJobPay.vue";
import FatherPaymentConfirmation from "@/components/dialogs/FatherPaymentConfirmation.vue";
import KidPaymentConfirmation from "@/components/dialogs/KidPaymentConfirmation.vue";

// import QRcode from "@/components/QRcode.vue";

export default {
  components: {
    MyHeader,
    HandleKid,
    SelectColorAvatar,
    ListActionHistory,
    CronJobPay,
    FatherPaymentConfirmation,
    KidPaymentConfirmation,
  },
  name: "ActionHistory",
  props: ["id"],
  data() {
    return {
      // actionsList: [],
      loading: true,
      parent: this.$store.getters.user,
      fieldParameter: [],
      sheet: false,
      showMenu: false,
      settingButtons: [],
      selectColor: false,
      openDialog: false,
      openFatherPayDialog: false,
      openKidPayDialog: false,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    openPayDialog() {
      if (this.isKidMode) {
        this.openKidPayDialog = true;
      } else {
        this.openFatherPayDialog = true;
      }
    },
    toPayKid() {
      this.$router.push(`/kid/${this.kid._id}`);
    },
    setSelectColor() {
      this.selectColor = !this.selectColor;
    },
    closeMenu() {
      this.showMenu = false;
    },
    openMenuKid() {
      this.showMenu = true;
      this.settingButtons = [
        {
          name: "דמי כיס",
          icon: "mdi-clock-time-eight",
          onClick: "openDialogAllowance",
        },
        { name: "קישור לילד", icon: "mdi-link", onClick: "kidURL" },
        {
          name: "עריכה",
          icon: "mdi-account-edit",
          onClick: "openSheetEditKid",
        },
      ];
    },

    btnEditClick(name) {
      this[name]();
    },

    save(prop) {
      if (this.$store.getters.action) {
        this.editAction(prop);
      } else {
        this.editKid(prop);
      }
    },
    openSheetEditKid() {
      this.fieldParameter = [{ name: "name", label: "שם פרטי" }];
      this.sheet = true;
      this.showMenu = false;
    },

    async deleteKid() {
      const alert = await this.mySwal("?למחוק");
      if (alert) {
        await this.$store.dispatch("user/delete-kid", { kidId: this.kid._id });
        this.goBack();
      }
    },
    async mySwal(messeg) {
      const value = await this.$swal({
        title: messeg,
        showDenyButton: true,
        confirmButtonText: `כן`,
        denyButtonText: `לא`,
        icon: "question",
      });
      return value.value;
    },
    async openDialogAllowance() {
      this.openDialog = true;
    },
    async editKid({ name }) {
      try {
        await this.$store.dispatch("user/edit-kid", {
          kidId: this.$store.getters.kid._id,
          name,
        });
        this.sheet = false;
      } catch (error) {
        this.sheet = false;
      }
    },
    kidURL() {
      navigator.clipboard.writeText(
        `${window.origin}/kid-link/${this.kid._id}`,
      );
      this.$store.commit("invokeSnack", {
        msg: "הקישור הועתק בהצלחה",
        type: "success",
      });
      this.showMenu = false;
    },

    openShowEditAction(id) {
      this.showMenu = true;
      this.$store.commit("action/set", id);

      this.settingButtons = [
        { name: "מחיקה", icon: "mdi-delete", onClick: "deleteAction" },
        {
          name: "עריכת פעולה",
          icon: "mdi-link",
          onClick: "openEditAction",
        },
      ];
    },
    async deleteAction() {
      await this.$store.dispatch("action/delete", this.$store.getters.action);
      this.$store.commit("action/unset");
      this.showMenu = false;
    },
    openEditAction() {
      this.toPayKid();
    },
  },
  async created() {
    try {
      if (this.$store.getters.kid) return;
      if (this.$store.getters.user) {
        return this.$store.commit("kid/setById", this.id);
      } else {
        await this.$store.dispatch("user/get-kid", this.id);
      }
    } catch (error) {
      this.$store.commit("invokeSnack", {
        msg: "אירעה שגיאה",
        type: "error",
      });
      console.log(error);
    }
  },
  async mounted() {
    this.loading = true;
    try {
      await this.$store.dispatch("actions/get", this.id);
      // this.actionsList = await this.$store.getters.actions;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    } catch (e) {
      this.$store.commit("invokeSnack", {
        msg: "אירעה שגיאה",
        type: "error",
      });
      this.loading = false;
    }
  },
  computed: {
    kid() {
      return this.$store.getters.kid;
    },
    user() {
      return this.$store.getters.user;
    },
    balance() {
      return this.$store.getters.kidBalance(this.kid._id) || this.kid.balance;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    isMobileMode() {
      return this.$vuetify.breakpoint.mobile;
    },
    isKidMode() {
      return !this.$store.getters.user;
    },
  },
  watch: {
    showMenu() {
      if (!this.showMenu) this.$store.commit("action/unset");
    },
  },
};
</script>

<style scoped>
.action-history > .header {
  height: 160px;
}
</style>
