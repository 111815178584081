<template>
  <my-dialog :title="'קוד לתשלום בחנות'" v-model="openModal" :max-width="400">
    <template v-slot:content>
      <v-row>
        <v-col>
          <QrCode :src="src" />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="center-all">
          <h1>
            {{ qr.code }}
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="center-all">
          <Timer
            v-if="qr.expireAt"
            :message="'תוקף הקוד נגמר עוד'"
            :timeout="qr.expireAt"
          />
        </v-col>
      </v-row>
    </template>
  </my-dialog>
</template>
<script>
import MyDialog from "@/components/generics/MyDialog.vue";
import QrCode from "@/components/QRcode.vue";
import Timer from "@/components/Timer.vue";
export default {
  name: "qrcode-handler",
  components: { MyDialog, QrCode, Timer },
  props: { value: Boolean, credit: Object, kidId: String },
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    src() {
      return `company/payment/${this.qr.code}`;
    },
    qr() {
      return this.$store.getters.qr || {};
    },
  },
  methods: {
    async submit() {
      if (!this.validateForm()) {
        return this.createErr(""); //TODO: create validation error message
      }
      this.openModal = false;
    },
  },
  async created() {
    const data = {
      credit: this.credit._id,
      company: this.credit.company?._id ?? this.credit.company,
      user: this.credit.user?._id ?? this.credit.user,
      kid: this.kidId,
    };
    await this.$store.dispatch("qr/store", data);
  },
};
</script>
