<template>
  <div class="center-all">
    <v-img :src="QRCode" max-height="300px" max-width="300px"></v-img>
  </div>
</template>

<script>
import QRCode from "qrcode";
const baseSrc = document.location.origin;
export default {
  name: "QRcode",
  props: { src: String },
  data() {
    return {
      QRCode: "",
    };
  },
  methods: {
    async generateQR(src) {
      try {
        return await QRCode.toDataURL(`${baseSrc}/${src}`);
      } catch (err) {
        console.error(err);
      }
    },
  },
  async mounted() {
    this.QRCode = await this.generateQR(this.src);
  },
  watch: {
    async src(newVal, oldVal) {
      console.log({ newVal, oldVal });
      if (newVal) this.QRCode = await this.generateQR(newVal);
    },
  },
};
</script>
