<template>
  <v-row>
    <!-- <v-col cols="2" class="d-flex justify-start align-center">
      {{ credit.company.name }}
    </v-col> -->
    <v-col class="d-flex justify-center align-center">
      <v-text-field
        ref="amount-input"
        hide-details
        v-model="amountApproved"
        @input="checkLimit"
        append-icon="mdi-currency-ils"
        label="סכום מאושר "
        outlined
        type="number"
        @blur="saveAmount"
        @focus="$event.target.select()"
      />
    </v-col>
    <v-col class="d-flex justify-space-around justify-center align-center">
      <span>נשאר:</span>
      <span>{{ limit - amount }}</span>
      <span><v-icon>mdi-currency-ils</v-icon></span>
    </v-col>
  </v-row>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "CompanyInputPay",
  props: {
    credit: Object,
    amount: { type: Number, default: 0 },
  },
  data() {
    return {
      amountApproved: 0,
    };
  },
  computed: {
    creditMerged() {
      if (this.credit?.company) {
        return cloneDeep(this.$store.getters.creditsApprovedMerged).find(
          (c) => {
            const id = this.credit?.company?._id ?? this.credit?.company;
            return c.company._id === id;
          },
        );
      } else {
        return {};
      }
    },
    kid() {
      return this.$store.getters.kid;
    },
    limit() {
      const initialValue = 0;

      const sumKidUsesCreditAmount = this.$store.getters.user.kids.reduce(
        (accumulator, currentValue) => {
          accumulator = Number(accumulator);
          if (this.kid._id === currentValue._id) return accumulator;
          console.log(this.credit.company);
          const credit = currentValue?.credits.find((c) => {
            return c.company?._id === this.credit.company._id;
          });

          if (credit?.amount) {
            return accumulator + Number(credit.amount);
          } else return accumulator;
        },
        initialValue,
      );

      const amount = this.creditMerged?.sumUses
        ? Number(this.creditMerged.amount) - Number(this.creditMerged?.sumUses)
        : Number(this.creditMerged.amount);

      return amount - sumKidUsesCreditAmount;
    },
  },
  methods: {
    checkLimit(amount) {
      const limit = Number(this.limit);
      const isLimited = Number(amount) > limit;
      if (isLimited) {
        this.amountApproved = limit;
        this.$refs["amount-input"].internalValue = limit;
        this.$refs["amount-input"].lazyValue = limit;
        this.$refs["amount-input"].$data.lazyValue = limit;
      }
    },
    saveAmount(e) {
      this.checkLimit(e.target.value);
      this.$emit("updateAmount", this.credit, this.amountApproved);
    },
  },
  mounted() {
    if (this.amount) {
      this.amountApproved = Number(this.amount);
    }
  },
};
</script>
