<template>
  <v-row class="mx-4">
    <v-col cols="3">
      <v-img
        sizes="50"
        class="mouse-pointer"
        @click="selectColor(1)"
        src="/imgs/users/1.png"
      />
    </v-col>
    <v-col cols="3">
      <v-img
        sizes="50"
        class="mouse-pointer"
        @click="selectColor(2)"
        src="/imgs/users/2.png"
      />
    </v-col>
    <v-col cols="3">
      <v-img
        sizes="50"
        class="mouse-pointer"
        @click="selectColor(3)"
        src="/imgs/users/3.png"
      />
    </v-col>
    <v-col cols="3">
      <v-img
        sizes="50"
        class="mouse-pointer"
        @click="selectColor(4)"
        src="/imgs/users/4.png"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SelectColorAvatar",
  methods: {
    async selectColor(num) {
      try {
        await this.$store.dispatch("user/edit-kid", {
          kidId: this.$store.getters.kid._id,
          color: num,
        });
        this.$emit("setSelectColor");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>