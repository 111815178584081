<template>
  <div>
    <p v-if="timeRemaining">{{ message }}: {{ formattedTime }}</p>
    <p v-else-if="timeoutMessage">{{ timeoutMessage }}</p>
  </div>
</template>

<script>
export default {
  name: "Timer",
  props: ["message", "timeout"],
  data() {
    return {
      timeoutMessage: "",
      timeRemaining: 0,
      intervalId: null,
    };
  },
  created() {
    // Call updateTimer immediately to set the initial value
    this.updateTimer();
    // Call updateTimer every second
    this.intervalId = setInterval(this.updateTimer, 1000);
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed to prevent memory leaks
    this.stopTimer();
  },
  computed: {
    endDate() {
      const timeout = new Date(this.timeout);
      console.log(timeout.getTime());
      return timeout.getTime();
    },
    formattedTime() {
      // Calculate minutes and seconds from timeRemaining
      if (!this.timeRemaining) return "";
      // Pad to 2 or 3 digits, default is 2
      function pad(n, z = 2) {
        return ("00" + n)?.slice(-z);
      }
      let s = this.timeRemaining;
      let ms = s % 1000;
      s = (s - ms) / 1000;
      let secs = s % 60;
      s = (s - secs) / 60;
      let mins = s % 60;
      let hrs = (s - mins) / 60;

      return pad(hrs) + ":" + pad(mins) + ":" + pad(secs);
    },
  },
  methods: {
    stopTimer() {
      clearInterval(this.intervalId);
    },
    updateTimer() {
      const now = new Date();
      const difference = this.endDate - now;
      if (difference > 0) {
        this.timeRemaining = difference;
      } else {
        this.stopTimer();
        this.timeRemaining = 0;
        this.timeoutMessage = "פג תוקף הקוד";
      }
    },
  },
};
</script>
