<template>
  <v-bottom-sheet v-model="openModal" max-width="700px">
    <v-sheet max-height="100%">
      <v-row justify="center" class="pt-10" @keydown.enter="save">
        <v-col cols="8" v-for="(field, index) in fieldParameter" :key="index">
          <v-text-field
            :ref="field[name]"
            v-model="form[field.name]"
            :label="field.label"
            required
            prepend-inner-icon="mdi-account"
            class="name"
            :autofocus="index == 0 ? true : false"
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6" class="mb-10">
          <v-btn
            block
            @click="save"
            class="gradient-btn"
            x-large
            v-text="'שמירה'"
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6">
          <v-btn
            block
            @click="$router.push({ name: 'ResetPassword' })"
            class="gradient-btn"
            x-large
            prepend-icon="mdi-lock-reset"
          >
            <v-list-item-title v-text="'עריכת סיסמה'" />
            <v-icon color="white">mdi-lock-reset</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="!isNew">
        <v-col cols="6" class="mb-10 white--text">
          <v-btn
            @click="deleteKid"
            block
            color="error"
            style="border-radius: 28px"
          >
            <v-list-item-title v-text="'מחיקה'" />
            <v-icon color="white">mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script>
export default {
  name: "HandleKid",
  props: {
    value: Boolean,
    isNew: { type: Boolean, default: false },
    fieldParameter: Array,
  },
  data() {
    return {
      name: "",
      form: {},
    };
  },
  computed: {
    kid() {
      return this.$store.getters.kid;
    },
    user() {
      return this.$store.getters.user;
    },
    action() {
      return this.$store.getters.action;
    },
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        this.close();
      },
    },
  },
  watch: {
    openModal() {
      if (this.openModal) {
        this.name = this.isNew
          ? ""
          : this.kid
          ? this.kid.name
          : this.user?.name;
      }
    },
    fieldParameter() {
      this.form = {};
      this.fieldParameter.forEach((field) => {
        this.$set(
          this.form,
          field.name,
          this.action
            ? this.action[field.name]
            : this.kid
            ? this.kid[field.name]
            : this.user[field.name],
        );
      });
    },
  },
  methods: {
    save() {
      const obj = Object.create(null);
      this.fieldParameter.map((filed) => {
        const key = filed.name;
        const value = this.form[filed.name];
        obj[key] = value;
        if (!value) delete obj[key];
        return;
      });
      obj["isNew"] = this.form.email ? "editSetting" : this.isNew;
      this.$emit("handleKid", obj);
      this.close();
    },
    async deleteKid() {
      const alert = await this.mySwal("?למחוק");
      if (alert) {
        try {
          await this.$store.dispatch("user/delete-kid", {
            kidId: this.kid._id,
          });
          this.$router.go(-1);
        } catch (e) {
          console.log(e);
        }
      }
    },
    async mySwal(message) {
      const value = await this.$swal({
        title: message,
        showDenyButton: true,
        confirmButtonText: `כן`,
        denyButtonText: `לא`,
        icon: "question",
      });
      return value.value;
    },
    close() {
      this.name = "";
    },
  },
};
</script>
