<template>
  <v-card-text>
    <v-timeline dense v-if="actions.length">
      <v-timeline-item
        v-for="action in actions"
        :key="action._id"
        small
        class="d-flex justify-space-between mb-6 pt-4 pl-6"
      >
        <v-row align="center" justify="space-between">
          <v-col cols="6" class="primaryText--text">
            <div>{{ formatDate(action.createdAt) }}</div>
            <div>{{ action.title }}</div>
          </v-col>
          <v-col cols="4" class="d-flex justify-centrer align-center">
            <div class="kid-balance">
              <span
                :class="`${
                  action.amount >= 0 ? 'primary--text' : 'red--text'
                } `"
              >
                {{ Math.abs(action.amount) }}
                {{ action.amount >= 0 ? "" : "-" }}
              </span>
              ₪
            </div>
          </v-col>
          <v-col cols="2" class="d-flex justify-end align-center">
            <v-btn
              rounded
              text
              color="darkerGrey"
              v-if="parent && !isAdmin && !action.isFromCredit"
              @click="openShowEditAction(action._id)"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
            <v-tooltip v-else-if="action.isFromCredit" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                  mdi-contactless-payment-circle
                </v-icon>
              </template>
              <span>בוצע על ידי בית עסק</span>
              <span v-if="action?.credit?.company?.name">
                - {{ action?.credit?.company?.name }}
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-timeline-item>
    </v-timeline>
    <div v-else class="d-flex align-center justify-center ma-16">
      <span>אין פעולות</span>
    </div>
  </v-card-text>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "ListActionHistory",
  // props: { actionsList: Array, sheet: Boolean },
  computed: {
    parent() {
      return this.$store.getters.user;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    actions() {
      return this.$store.getters.actions;
    },
  },
  methods: {
    formatDate(actionDate) {
      return dayjs(actionDate).format("DD/MM/YYYY");
    },
    openShowEditAction(id) {
      this.$emit("openShowEditAction", id);
    },
  },
};
</script>

<style></style>
