<template>
  <div>
    <my-dialog :title="'בחר סכום ותזמון לדמי כיס'" v-model="openModal">
      <template v-slot:content>
        <v-row>
          <v-col>
            <v-text-field
              type="number"
              label="סכום"
              v-model="form.amount"
              autofocus
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field label="שם הפעולה" v-model="form.title" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-radio-group v-model="form.timed">
              <div v-for="job in jobs" :key="job.value">
                <v-radio :label="job.label" :value="job.value"></v-radio>
              </div>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row class="pt-0 mt-0">
          <v-col class="boldText--text text-end pt-0 mt-0">
            <span>*</span>
            <span class="h6">הסכום יעבור בשעה 00:00 במועד שבחרת</span>
          </v-col>
        </v-row>
        <v-row v-if="err">
          <v-col class="error--text text-center">
            {{ err }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn text color="primary" v-text="'שמירה'" @click="submit" />
        <v-btn
          v-if="allowance.timed"
          text
          color="red"
          v-text="'מחיקה'"
          @click="destroy"
        />
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from "./generics/MyDialog.vue";
export default {
  name: "corn-job-pay",
  components: { MyDialog },
  props: { value: Boolean, allowance: { type: Object, default: () => ({}) } },
  data() {
    return {
      form: {
        amount: 0,
        timed: "monthly",
        title: "",
      },
      jobs: [
        { label: "כל יום", value: "daily" },
        { label: "כל שבוע", value: "weekly" },
        { label: "כל חודש", value: "monthly" },
      ],
      err: null,
    };
  },
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    kid() {
      return this.$store.getters.kid;
    },
  },
  methods: {
    async submit() {
      if (!this.validateForm()) {
        return this.createErr("צריך לבחור סכום");
      }
      const action = this.allowance.timed ? "update" : "create";
      this.$store.dispatch("allowance/" + action, this.form);
      this.$emit("closeMenu");
      this.openModal = false;
    },
    async destroy() {
      this.$store.dispatch("allowance/delete", this.form);
      this.$emit("closeMenu");
      this.openModal = false;
    },
    validateForm() {
      return this.form.amount > 0;
    },
    createErr(msg) {
      this.err = msg;
      setTimeout(() => {
        this.err = null;
      }, 2000);
      return;
    },
  },
  mounted() {
    const a = this.allowance;
    if (a?.timed) {
      this.form.timed = a?.timed;
      this.form.amount = a?.amount;
      this.form.title = a?.title;
      this.form.allowanceId = a._id ? a?._id : a.allowanceId;
    }
    this.form.kidId = this.kid?._id;
  },
};
</script>
